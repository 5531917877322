



















import {Component, Vue} from "vue-property-decorator";
import Details from "@/components/payroll/business/employments/employee/Details.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import EmployeeService from "@/services/EmployeeService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { processError } from "@/utils/ComponentUtils";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");

@Component({
	components: {Details}
})
export default class EmployeeCard extends Vue {

	private componentMap: Map<string, Promise<any>> = new Map<string, Promise<any>>(
		[
			["Employee details", import('./Details.vue')]
			// ["Tariffication", import('./Tariffication.vue')],
			// ["Payroll details", import('./EmployeePayrollDetails.vue')]
		]
	)

	private components: Map<string, () => Vue> = new Map<string, () => Vue>()

	private employee: EmployeeDto = new EmployeeDto()

	private message: string | null = null;

	private reload: () => void = this.loadEmployee

	private successful = false;

	@AppModule.Action
	private stopLoading!: () => void

	@AppModule.Action
	private startLoading!: () => void

	private loadEmployee(): Promise<void> {
		this.startLoading();
		return EmployeeService.getEmployeeById(this.$route.params.employeeId).then(
			res => {
				this.employee = res.data;
				this.stopLoading();
			},
			err => processError(
				err,
				this,
				{
					callback: () => this.$modal.hideAll()
				}
			)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	public async created() {
		await this.loadEmployee()
		if (Workspaces.isUserSublimeEmployee) {
			this.componentMap.set("Tariffication", import('./Tariffication.vue'))
			this.componentMap.set("Payroll details", import('./EmployeePayrollDetails.vue'))
			this.componentMap.set("Paystub", import('./EmployeePaystub.vue'))
		}
		this.components = await this.processComponentLinks(this.componentMap);
	}

	private async processComponentLinks(imports: Map<string, Promise<any>>): Promise<Map<string, () => Vue>> {
		let map: Map<string, () => Vue> = new Map<string, () => Vue>();
		for (let key of imports.keys()) {
			let value = await imports.get(key)!.then(res => res.default);
			map.set(key, value);
		}
		return map;
	}

}

